<div class="training-overview-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-6">
                <div class="training-overview-content">
                    <h3>Boost Your Online Employee Training With Easy Way</h3>
                </div>
            </div>
            <div class="col-lg-5 col-md-6">
                <div class="training-overview-btn">
                    <a routerLink="/contact" class="default-btn">
                        <i class='bx bx-move-horizontal icon-arrow before'></i>
                        <span class="label">Start a Free Trial</span>
                        <i class="bx bx-move-horizontal icon-arrow after"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>