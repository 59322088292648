import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-free-trial-two',
    standalone: true,
    imports: [RouterLink],
    templateUrl: './free-trial-two.component.html',
    styleUrls: ['./free-trial-two.component.scss']
})
export class FreeTrialTwoComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {}

}