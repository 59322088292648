<div
    class="hero-banner bg-white"
    [class.dark-hero-banner]="themeService.isDark()"
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-banner-content black-color">
                            <span class="sub-title">We Challenge Young Minds</span>
                            <h1>A modern, global courses for a Modern World!</h1>
                            <p>Raque supports students by introducing collaborators outside R, internships and research experience abroad.</p>
                            <div class="btn-box">
                                <a routerLink="/courses-2-columns-style-1" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                <a routerLink="/contact" class="optional-btn">Get Started Free</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-banner-image text-center">
                            <img src="assets/img/banner-img3.jpg" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>