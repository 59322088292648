<section
    class="motivation-courses-area pb-70"
    [class.dark-motivation-courses-area]="themeService.isDark()"
>
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Discover Courses</span>
            <h2>Our Popular Online Courses</h2>
            <a routerLink="/courses-2-columns-style-1" class="default-btn"><i class='bx bx-show-alt icon-arrow before'></i><span class="label">All Courses</span><i class="bx bx-show-alt icon-arrow after"></i></a>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-motivation-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/motivation-course/courses/courses-1.jpg" alt="image"></a>
                        <div class="price">$188</div>
                    </div>
                    <div class="courses-content">
                        <div class="courses-tag">
                            <a routerLink="/" class="d-block">DESIGN</a>
                        </div>
                        <div class="course-author d-flex align-items-center justify-content-between">
                            <div class="author-box">
                                <img src="assets/img/user1.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Margaret James</span>
                            </div>
                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <div class="rating-total">(0 Rating)</div>
                            </div>
                        </div>
                        <h3><a routerLink="/single-courses" class="d-inline-block">Information About UI/UX Design Degree</a></h3>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 4 students
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 10 lessons
                            </li>
                            <li class="courses-time">
                                <i class='bx bx-time-five'></i> 1 Hours
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-motivation-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/motivation-course/courses/courses-2.jpg" alt="image"></a>
                        <div class="price">$166</div>
                    </div>
                    <div class="courses-content">
                        <div class="courses-tag">
                            <a routerLink="/" class="d-block">BUSINESS</a>
                        </div>
                        <div class="course-author d-flex align-items-center justify-content-between">
                            <div class="author-box">
                                <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Kimberly Joseph</span>
                            </div>
                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <div class="rating-total">(0 Rating)</div>
                            </div>
                        </div>
                        <h3><a routerLink="/single-courses" class="d-inline-block">React – The Complete Guide (React, Router, Redux)</a></h3>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 9 students
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 7 lessons
                            </li>
                            <li class="courses-time">
                                <i class='bx bx-time-five'></i> 1 Hours
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-motivation-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/motivation-course/courses/courses-3.jpg" alt="image"></a>
                        <div class="price">$166</div>
                    </div>
                    <div class="courses-content">
                        <div class="courses-tag">
                            <a routerLink="/" class="d-block">DEVELOPMENT</a>
                        </div>
                        <div class="course-author d-flex align-items-center justify-content-between">
                            <div class="author-box">
                                <img src="assets/img/user3.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Dennis Hines</span>
                            </div>
                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <div class="rating-total">(0 Rating)</div>
                            </div>
                        </div>
                        <h3><a routerLink="/single-courses" class="d-inline-block">Certified JavaScript with Free Project Course</a></h3>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 12 students
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 8 lessons
                            </li>
                            <li class="courses-time">
                                <i class='bx bx-time-five'></i> 1 Hours
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>