<section class="premium-access-area ptb-100">
    <div class="container">
        <div class="premium-access-content">
            <span class="sub-title">Go at your own pace</span>
            <h2>Give their limitless potential unlimited access</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            <a routerLink="/register" class="default-btn"><i class='bx bx-user-circle icon-arrow before'></i><span class="label">Give Premium Access</span><i class="bx bx-user-circle icon-arrow after"></i></a>
        </div>
    </div>
    <div class="business-shape9"><img src="assets/img/business-coaching/business-shape7.png" alt="image"></div>
    <div class="business-shape10"><img src="assets/img/business-coaching/business-shape8.png" alt="image"></div>
</section>