<div
    class="motivation-categories-area pt-100 pb-70"
    [class.dark-motivation-categories-area]="themeService.isDark()"
>
    <div class="container">
        <div class="section-title">
            <span class="sub-title">COURSES CATEGORIES</span>
            <h2>Browse Trending Categories</h2>
        </div>
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-3 col-md-6">
                <div class="motivation-categories-card mb-30">
                    <ul class="motivation-categories-list">
                        <li>
                            <div class="icon">
                                <i class="bx bx-shape-triangle"></i>
                            </div>
                            <h3>
                                <a routerLink="/courses-3-columns-style-1">Technology</a>
                            </h3>
                            <span>12 Courses</span>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="bx bx-first-aid"></i>
                            </div>
                            <h3>
                                <a routerLink="/courses-3-columns-style-1">Health</a>
                            </h3>
                            <span>6 Courses</span>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="bx bx-book-reader"></i>
                            </div>
                            <h3>
                                <a routerLink="/courses-3-columns-style-1">Math</a>
                            </h3>
                            <span>4 Courses</span>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="bx bx-font-family"></i>
                            </div>
                            <h3>
                                <a routerLink="/courses-3-columns-style-1">Language</a>
                            </h3>
                            <span>9 Courses</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="motivation-categories-card mb-30">
                    <div class="motivation-categories-image">
                        <img src="assets/img/motivation-course/categories/categories.png" alt="image">
                        <span>1500+ Courses Availabel <a href="courses-2-columns-style-1.html">View all Courses</a></span>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="motivation-categories-card mb-30">
                    <ul class="motivation-categories-list">
                        <li>
                            <div class="icon">
                                <i class="bx bxs-drink"></i>
                            </div>
                            <h3>
                                <a routerLink="/courses-3-columns-style-1">Science</a>
                            </h3>
                            <span>5 Courses</span>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="bx bx-briefcase-alt-2"></i>
                            </div>
                            <h3>
                                <a routerLink="/courses-3-columns-style-1">Business</a>
                            </h3>
                            <span>10 Courses</span>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="bx bx-layer"></i>
                            </div>
                            <h3>
                                <a routerLink="/courses-3-columns-style-1">Graphics Design</a>
                            </h3>
                            <span>15 Courses</span>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="bx bx-target-lock"></i>
                            </div>
                            <h3>
                                <a routerLink="/courses-3-columns-style-1">Marketing</a>
                            </h3>
                            <span>13 Courses</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="motivation-categories-shape">
        <img src="assets/img/motivation-course/categories/shape.png" alt="image">
    </div>
</div>