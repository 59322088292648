<app-header-style-two></app-header-style-two>
<!-- ng-circle-progress -->

<div class="page-title-area-dash item-bg1">
    <div class="container">
        <div
            class="contact-info-area"
            [class.dark-contact-info-area]="themeService.isDark()"
        >
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-3 col-md-6 col-sm-6">
                        <div class="contact-info-box mb-30">
                            <h3>Current Cycle &nbsp;Year</h3>
                            <circle-progress
                                [percent]="66"
                                [radius]="70"
                                [outerStrokeWidth]="16"
                                [innerStrokeWidth]="8"
                                [outerStrokeColor]="'#78C000'"
                                [innerStrokeColor]="'#C7E596'"
                                [animation]="true"
                                [startFromZero]="true"
                                [animationDuration]="800"
                            ></circle-progress>
                            <h6 style="font-weight: bold">Year 2 of 3 Years</h6>
                            <p>Current Cycle Status</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6">
                        <div class="contact-info-box mb-30">
                            <h3>Current CPD Module Credits</h3>
                            <circle-progress
                                [percent]="33"
                                [radius]="70"
                                [outerStrokeWidth]="16"
                                [innerStrokeWidth]="8"
                                [outerStrokeColor]="'#1f5aab'"
                                [innerStrokeColor]="'#7390b7'"
                                [animation]="true"
                                [startFromZero]="true"
                                [animationDuration]="800"
                            ></circle-progress>
                            <h6 style="font-weight: bold">
                                <span style="color: rgb(6, 99, 240)">4</span>
                                credits of
                                <span style="color: rgb(6, 99, 240)">12</span>
                            </h6>
                            <p>12 Required per Cycle</p>
                        </div>
                    </div>
                    <div
                        class="col-lg-3 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3"
                    >
                        <div class="contact-info-box mb-30">
                            <h3>Available E-Learning</h3>
                            <circle-progress
                                [percent]="55"
                                [radius]="70"
                                [outerStrokeWidth]="16"
                                [innerStrokeWidth]="8"
                                [outerStrokeColor]="'#00888d'"
                                [innerStrokeColor]="'#739a9b'"
                                [animation]="true"
                                [startFromZero]="true"
                                [animationDuration]="800"
                            ></circle-progress>
                            <h6 style="font-weight: bold">Year 2 of 3 Years</h6>
                            <p>Current Cycle Status</p>
                        </div>
                    </div>
                    <div
                        class="col-lg-3 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3"
                    >
                        <div class="contact-info-box mb-30">
                            <h3>Current Cycle<br />&nbsp;</h3>
                            <circle-progress
                                [percent]="100"
                                [maxPercent]="100"
                                [radius]="70"
                                [outerStrokeWidth]="16"
                                [innerStrokeWidth]="8"
                                [units]="'cycle'"
                                [title]="'1st '"
                                [outerStrokeColor]="'#e000fa'"
                                [innerStrokeColor]="'#ab83b0'"
                                [animation]="true"
                                [startFromZero]="true"
                                [animationDuration]="800"
                            ></circle-progress>
                            <h6 style="font-weight: bold">Cycle 1</h6>
                            <p>Started 2023</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div
    class="my-dashboard-area ptb-73"
    [class.dark-my-dashboard-area]="themeService.isDark()"
>
    <div class="container">
        <!-- <div
            class="contact-info-area pt-100 pb-70"
            [class.dark-contact-info-area]="themeService.isDark()"
        >
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-3 col-md-6 col-sm-6">
                        <div class="contact-info-box mb-30">
                            <h3>Current Cycle &nbsp;Year</h3>
                            <circle-progress
                                [percent]="66"
                                [radius]="70"
                                [outerStrokeWidth]="16"
                                [innerStrokeWidth]="8"
                                [outerStrokeColor]="'#78C000'"
                                [innerStrokeColor]="'#C7E596'"
                                [animation]="true"
                                [startFromZero]="true"
                                [animationDuration]="800"
                            ></circle-progress>
                            <h6 style="font-weight: bold">Year 2 of 3 Years</h6>
                            <p>Current Cycle Status</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6">
                        <div class="contact-info-box mb-30">
                            <h3>Current CPD Module Credits</h3>
                            <circle-progress
                                [percent]="33"
                                [radius]="70"
                                [outerStrokeWidth]="16"
                                [innerStrokeWidth]="8"
                                [outerStrokeColor]="'#1f5aab'"
                                [innerStrokeColor]="'#7390b7'"
                                [animation]="true"
                                [startFromZero]="true"
                                [animationDuration]="800"
                            ></circle-progress>
                            <h6 style="font-weight: bold">
                                <span style="color: rgb(6, 99, 240)">4</span>
                                credits of
                                <span style="color: rgb(6, 99, 240)">12</span>
                            </h6>
                            <p>12 Required per Cycle</p>
                        </div>
                    </div>
                    <div
                        class="col-lg-3 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3"
                    >
                        <div class="contact-info-box mb-30">
                            <h3>Available E-Learning</h3>
                            <circle-progress
                                [percent]="55"
                                [radius]="70"
                                [outerStrokeWidth]="16"
                                [innerStrokeWidth]="8"
                                [outerStrokeColor]="'#00888d'"
                                [innerStrokeColor]="'#739a9b'"
                                [animation]="true"
                                [startFromZero]="true"
                                [animationDuration]="800"
                            ></circle-progress>
                            <h6 style="font-weight: bold">Year 2 of 3 Years</h6>
                            <p>Current Cycle Status</p>
                        </div>
                    </div>
                    <div
                        class="col-lg-3 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3"
                    >
                        <div class="contact-info-box mb-30">
                            <h3>Current Cycle<br />&nbsp;</h3>
                            <circle-progress
                                [percent]="100"
                                [maxPercent]="100"
                                [radius]="70"
                                [outerStrokeWidth]="16"
                                [innerStrokeWidth]="8"
                                [units]="'cycle'"
                                [title]="'1st '"
                                [outerStrokeColor]="'#e000fa'"
                                [innerStrokeColor]="'#ab83b0'"
                                [animation]="true"
                                [startFromZero]="true"
                                [animationDuration]="800"
                            ></circle-progress>
                            <h6 style="font-weight: bold">Cycle 1</h6>
                            <p>Started 2023</p>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="myDashboard-profile">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-5">
                    <div class="profile-image">
                        <img
                            src="assets/img/instructor/instructor1.jpg"
                            alt="image"
                        />
                    </div>
                </div>
                <div class="col-lg-8 col-md-7">
                    <div class="profile-content">
                        <h3>James Anderson</h3>
                        <p>Practitioner</p>
                        <ul class="contact-info">
                            <li>
                                <p><strong>ID NUMBER: </strong>9347347935734</p>
                            </li>
                            <li>
                                <p><strong>PIN: </strong>014583</p>
                            </li>
                            <li>
                                <p>
                                    <strong>Email: </strong
                                    >hello&#64;jamesanderson.com
                                </p>
                            </li>
                        </ul>
                        <ul class="social">
                            <li>
                                <a href="#" class="d-block" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="d-block" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="d-block" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="d-block" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="d-block" target="_blank"
                                    ><i class="bx bxl-pinterest-alt"></i
                                ></a>
                            </li>
                        </ul>
                        <a routerLink="/" class="myDashboard-logout">Logout</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="myDashboard-navigation">
            <ul>
                <li>
                    <a (click)="isActivePdp()" [ngClass]="pdp ? 'active' : ''"
                        ><i class="bx bxs-dashboard"></i> Personal Development
                        Plan</a
                    >
                </li>
                <li>
                    <a (click)="isActiveCpd()" [ngClass]="cpd ? 'active' : ''"
                        ><i class="bx bx-edit"></i> CPD Status</a
                    >
                </li>
                <!-- <li>
                    <a routerLink="/downloads"
                        ><i class="bx bx-download"></i> Downloads</a
                    >
                </li>
                <li>
                    <a routerLink="/edit-address"
                        ><i class="bx bx-home-alt"></i> Addresses</a
                    >
                </li>
                <li>
                    <a routerLink="/edit-account"
                        ><i class="bx bx-edit"></i> Account Details</a
                    >
                </li>
                <li>
                    <a routerLink="/"><i class="bx bx-log-out"></i> Logout</a>
                </li> -->
            </ul>
        </div>
        <div class="myDashboard-content" *ngIf="pdp">
            <!-- <p>
                Hello <strong>James Anderson</strong> (not
                <strong>James Anderson</strong>?
                <a routerLink="/my-dashboard">Log out</a>)
            </p>
            <p>
                From your account dashboard you can view your
                <a routerLink="/orders">recent orders</a>, manage your
                <a routerLink="/edit-address">shipping and billing addresses</a
                >, <a routerLink="/edit-account">edit your password</a>, and
                <a routerLink="/edit-account">account details</a>.
            </p> -->
            <h3>Personal Development Plan</h3>
            <div class="recent-orders-table table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Plan Year</th>
                            <th>Cycle</th>
                            <th>Date Created</th>
                            <th>Submitted</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>2023</td>
                            <td>1</td>
                            <td>26 Mar 2024</td>
                            <td>Yes</td>
                        </tr>

                        <tr>
                            <td>2023</td>
                            <td>1</td>
                            <td>26 Mar 2024</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>2023</td>
                            <td>1</td>
                            <td>26 Mar 2024</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>2023</td>
                            <td>1</td>
                            <td>26 Mar 2024</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>2023</td>
                            <td>1</td>
                            <td>26 Mar 2024</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>2023</td>
                            <td>1</td>
                            <td>26 Mar 2024</td>
                            <td>Yes</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="myDashboard-content" *ngIf="cpd">
            <!-- <p>
                Hello <strong>James Anderson</strong> (not
                <strong>James Anderson</strong>?
                <a routerLink="/my-dashboard">Log out</a>)
            </p>
            <p>
                From your account dashboard you can view your
                <a routerLink="/orders">recent orders</a>, manage your
                <a routerLink="/edit-address">shipping and billing addresses</a
                >, <a routerLink="/edit-account">edit your password</a>, and
                <a routerLink="/edit-account">account details</a>.
            </p> -->
            <h3>CPD Status</h3>
            <div class="recent-orders-table table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Credits</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Mandatory Disclosure Forms</td>
                            <td>1,00</td>
                            <td>26 Mar 2024</td>
                        </tr>
                        <tr>
                            <td>
                                FICA and the Risk Management and Compliance
                                Programmes
                            </td>
                            <td>1,00</td>
                            <td>26 Mar 2024</td>
                        </tr>
                        <tr>
                            <td>Community Schemes</td>
                            <td>1,00</td>
                            <td>26 Mar 2024</td>
                        </tr>
                        <tr>
                            <td>Effective Communication Negotiation Skills</td>
                            <td>1,00</td>
                            <td>26 Mar 2024</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
