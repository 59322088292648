<div class="container">
    <div class="section-title text-start">
        <span class="sub-title">TESTIMONIALS</span>
        <h2>Happy Students Says</h2>
        <a routerLink="/feedback" class="default-btn"><i class='bx bx-show-alt icon-arrow before'></i><span class="label">View All Testimonials </span><i class="bx bx-show-alt icon-arrow after"></i></a>
    </div>
</div>
<div
    class="container-fluid"
    [class.dark-testimonials-area]="themeService.isDark()"
>
    <div class="testimonials-wrap-slides">
        <owl-carousel-o [options]="testimonialsWrapSlides">
            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                        <div class="title">
                            <h3>James Anderson</h3>
                            <span>Copyrighter</span>
                        </div>
                    </div>
                    <p>“If a man empties his purse into his head, no man can take it away from him. An investment in knowledge always pays the best interest.”</p>
                    <div class="review-stars-rated">
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/img/user2.jpg" class="rounded-circle" alt="image">
                        <div class="title">
                            <h3>Lina D'Souza</h3>
                            <span>Designer</span>
                        </div>
                    </div>
                    <p>“Keep away from people who try to belittle your ambitions. Small people always do that, but the really great make you feel can become great.”</p>
                    <div class="review-stars-rated">
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/img/user3.jpg" class="rounded-circle" alt="image">
                        <div class="title">
                            <h3>David Warner</h3>
                            <span>Art Director</span>
                        </div>
                    </div>
                    <p>“The difference between school and life? In school, you're taught a lesson and then given a test. In life, you're given a test that teaches you a lesson.”</p>
                    <div class="review-stars-rated">
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                        <div class="title">
                            <h3>James Anderson</h3>
                            <span>Copyrighter</span>
                        </div>
                    </div>
                    <p>“If a man empties his purse into his head, no man can take it away from him. An investment in knowledge always pays the best interest.”</p>
                    <div class="review-stars-rated">
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/img/user2.jpg" class="rounded-circle" alt="image">
                        <div class="title">
                            <h3>Lina D'Souza</h3>
                            <span>Designer</span>
                        </div>
                    </div>
                    <p>“Keep away from people who try to belittle your ambitions. Small people always do that, but the really great make you feel can become great.”</p>
                    <div class="review-stars-rated">
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/img/user3.jpg" class="rounded-circle" alt="image">
                        <div class="title">
                            <h3>David Warner</h3>
                            <span>Art Director</span>
                        </div>
                    </div>
                    <p>“The difference between school and life? In school, you're taught a lesson and then given a test. In life, you're given a test that teaches you a lesson.”</p>
                    <div class="review-stars-rated">
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                        <div class="title">
                            <h3>James Anderson</h3>
                            <span>Copyrighter</span>
                        </div>
                    </div>
                    <p>“If a man empties his purse into his head, no man can take it away from him. An investment in knowledge always pays the best interest.”</p>
                    <div class="review-stars-rated">
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/img/user2.jpg" class="rounded-circle" alt="image">
                        <div class="title">
                            <h3>Lina D'Souza</h3>
                            <span>Designer</span>
                        </div>
                    </div>
                    <p>“Keep away from people who try to belittle your ambitions. Small people always do that, but the really great make you feel can become great.”</p>
                    <div class="review-stars-rated">
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/img/user3.jpg" class="rounded-circle" alt="image">
                        <div class="title">
                            <h3>David Warner</h3>
                            <span>Art Director</span>
                        </div>
                    </div>
                    <p>“The difference between school and life? In school, you're taught a lesson and then given a test. In life, you're given a test that teaches you a lesson.”</p>
                    <div class="review-stars-rated">
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>