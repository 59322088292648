<section
    class="program-area pt-100 pb-70"
    [class.dark-program-area]="themeService.isDark()"
>
    <div class="container">
        <div class="section-title">
            <h2 class="playfair-display">Choose Your Program Bellow to See How We Can Help</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-program-box">
                    <div class="shape"><img src="assets/img/health-coaching/list-shape1.png" alt="image"></div>
                    <div class="icon">
                        <i class="flaticon-diet"></i>
                    </div>
                    <h3 class="playfair-display">Certification Courses</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    <a routerLink="/" class="link-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-program-box">
                    <div class="shape"><img src="assets/img/health-coaching/list-shape2.png" alt="image"></div>
                    <div class="icon">
                        <i class="flaticon-healthy-food"></i>
                    </div>
                    <h3 class="playfair-display">Certification Courses</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    <a routerLink="/" class="link-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-program-box">
                    <div class="shape"><img src="assets/img/health-coaching/list-shape3.png" alt="image"></div>
                    <div class="icon">
                        <i class="flaticon-pineapple"></i>
                    </div>
                    <h3 class="playfair-display">Certification Courses</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    <a routerLink="/" class="link-btn">Read More</a>
                </div>
            </div>
        </div>
    </div>
    <div class="health-coaching-shape5"><img src="assets/img/health-coaching/health-coaching-shape5.png" alt="image"></div>
</section>