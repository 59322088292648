import { Component, OnInit } from "@angular/core";
import { ThemeCustomizerService } from "../../common/theme-customizer/theme-customizer.service";
import { HeaderStyleTwoComponent } from "../../common/header-style-two/header-style-two.component";
import { RouterLink } from "@angular/router";
import lgVideo from "lightgallery/plugins/video";
import { BeforeSlideDetail } from "lightgallery/lg-events";
import { LightgalleryModule } from "lightgallery/angular";

@Component({
    selector: "app-courses-details-page",
    standalone: true,
    imports: [HeaderStyleTwoComponent, LightgalleryModule, RouterLink],
    templateUrl: "./courses-details-page.component.html",
    styleUrls: ["./courses-details-page.component.scss"],
})
export class CoursesDetailsPageComponent implements OnInit {
    isToggled = false;

    constructor(public themeService: ThemeCustomizerService) {
        this.themeService.isToggled$.subscribe((isToggled) => {
            this.isToggled = isToggled;
        });
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    settings = {
        counter: false,
        plugins: [lgVideo],
    };
    onBeforeSlide = (detail: BeforeSlideDetail): void => {
        const { index, prevIndex } = detail;
        console.log(index, prevIndex);
    };

    ngOnInit(): void {}
}
