<section class="team-area bg-dark bg-0b0517 pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">TRAIN WITH EXPERTS</span>
            <h2>Team of Expert Coaches</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-gym-trainer">
                    <img src="assets/img/trainer/1.jpg" alt="image">
                    <div class="content">
                        <span>Personal Trainer</span>
                        <h3><a routerLink="/">Sarah Taylor</a></h3>
                        <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-gym-trainer">
                    <img src="assets/img/trainer/2.jpg" alt="image">
                    <div class="content">
                        <span>Personal Trainer</span>
                        <h3><a routerLink="/">Jason Statham</a></h3>
                        <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-gym-trainer">
                    <img src="assets/img/trainer/3.jpg" alt="image">
                    <div class="content">
                        <span>Personal Trainer</span>
                        <h3><a routerLink="/">Jasika Morn</a></h3>
                        <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-gym-trainer">
                    <img src="assets/img/trainer/4.jpg" alt="image">
                    <div class="content">
                        <span>Personal Trainer</span>
                        <h3><a routerLink="/">John Smith</a></h3>
                        <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>