<div class="become-box-area pb-70">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-12">
                <div class="become-box-item mb-30">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="become-box-image">
                                <img src="assets/img/motivation-course/become-1.png" alt="image">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="become-box-content">
                                <h3>Become a Partner</h3>
                                <p>Choose from hundreds of free courses or get a degree or certificate at a breakthrough price learn at your own pace.</p>
                                <div class="btn-box">
                                    <a routerLink="/contact" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">Apply Now</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="become-box-item with-gradient-color mb-30">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="become-box-image">
                                <img src="assets/img/motivation-course/become-2.png" alt="image">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="become-box-content">
                                <h3>Become a Teacher</h3>
                                <p>Choose from hundreds of free courses or get a degree or certificate at a breakthrough price learn at your own pace.</p>
                                <div class="btn-box">
                                    <a routerLink="/contact" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">Get Started</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>