<section
    class="about-area pt-100"
    [class.dark-about-area]="themeService.isDark()"
>
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content left-content">
                    <span class="sub-title">About Us</span>
                    <h2>Learn New Skills to go ahead for Your Career</h2>
                    <h6>We can support student forum 24/7 for national and international students.</h6>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea commodo consequat.</p>
                    <div class="features-text">
                        <h5><i class='bx bx-planet'></i>A place where you can achieve</h5>
                        <p>Education encompasses both the teaching and learning of knowledge, proper conduct, and technical competency.</p>
                    </div>
                    <div class="signature">
                        <img src="assets/img/signature.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-right-image">
                    <img src="assets/img/about/about4.jpg" alt="image">
                    <img src="assets/img/about/about3.jpg" alt="image">
                    <div class="text-box">
                        <div class="inner">
                            Trusted By
                            <span>75K+</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>