<section
    class="services-area pt-100 pb-70"
    [class.dark-services-area]="themeService.isDark()"
>
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Do not wait for tomorrow</span>
            <h2 class="playfair-display">You’ve tried dieting a million times. Now try something different.</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-yoga"></i>
                    </div>
                    <h3 class="playfair-display">Daily Excersize</h3>
                    <p>Lorem ipsum dolor sit amet, elitt adipiscing elit, sed do eiusmodd tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-yoga-1"></i>
                    </div>
                    <h3 class="playfair-display">Find Your Balance</h3>
                    <p>Lorem ipsum dolor sit amet, elitt adipiscing elit, sed do eiusmodd tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-lotus"></i>
                    </div>
                    <h3 class="playfair-display">Personal Program</h3>
                    <p>Lorem ipsum dolor sit amet, elitt adipiscing elit, sed do eiusmodd tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-tomato"></i>
                    </div>
                    <h3 class="playfair-display">Natural Process</h3>
                    <p>Lorem ipsum dolor sit amet, elitt adipiscing elit, sed do eiusmodd tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-yoga-2"></i>
                    </div>
                    <h3 class="playfair-display">Immune System</h3>
                    <p>Lorem ipsum dolor sit amet, elitt adipiscing elit, sed do eiusmodd tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-strawberry"></i>
                    </div>
                    <h3 class="playfair-display">Gives You Energy</h3>
                    <p>Lorem ipsum dolor sit amet, elitt adipiscing elit, sed do eiusmodd tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="health-coaching-shape1"><img src="assets/img/health-coaching/health-coaching-shape1.png" alt="image"></div>
    <div class="health-coaching-shape2"><img src="assets/img/health-coaching/health-coaching-shape2.png" alt="image"></div>
</section>