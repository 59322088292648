<section
    class="courses-area pt-100 pb-70"
    [class.dark-courses-area]="themeService.isDark()"
>
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Discover Courses</span>
            <h2>Our Popular Online Courses</h2>
            <a routerLink="/courses-2-columns-style-1" class="default-btn"><i class='bx bx-show-alt icon-arrow before'></i><span class="label">All Courses</span><i class="bx bx-show-alt icon-arrow after"></i></a>
        </div>
        <ul class="nav-tabset">
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
                <span class="active" (click)="switchTab($event, 'tab1')">All (06)</span>
            </li>
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab2'}">
                <span (click)="switchTab($event, 'tab2')">Business (02)</span>
            </li>
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab3'}">
                <span (click)="switchTab($event, 'tab3')">Design (05)</span>
            </li>
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab4'}">
                <span (click)="switchTab($event, 'tab4')">Development (04)</span>
            </li>
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab5'}">
                <span (click)="switchTab($event, 'tab5')">Language (02)</span>
            </li>
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab6'}">
                <span (click)="switchTab($event, 'tab6')">Management (03)</span>
            </li>
            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab7'}">
                <span (click)="switchTab($event, 'tab7')">Photography (04)</span>
            </li>
        </ul>
        <div class="tabs-container">
            <div class="pane" id="tab1" *ngIf="currentTab === 'tab1'">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Business</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user1.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Steven Smith</span>
                                </div>
                                <h3><a routerLink="/single-courses" class="d-inline-block">Ednuv Professional IT Expert Certificate Course</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        Free
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses2.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Design</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Sarah Taylor</span>
                                </div>
                                <h3><a routerLink="/single-courses" class="d-inline-block">Certified Graphic Design with Free Project Course</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star-half'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.5 (2 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 15 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 10 lessons
                                    </li>
                                    <li class="courses-price">
                                        $250
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses3.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Development</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user3.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>James Anderson</span>
                                </div>
                                <h3><a routerLink="/single-courses" class="d-inline-block">Photography Crash Course for Photographer</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bx-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 5 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 5 lessons
                                    </li>
                                    <li class="courses-price">
                                        $150
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses4.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Language</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user4.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Liam King</span>
                                </div>
                                <h3><a routerLink="/single-courses" class="d-inline-block">Web Design for Developing Technology with Joy</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        <span>$200</span>
                                        $195
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses5.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Management</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user5.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Lina D'Souza</span>
                                </div>
                                <h3><a routerLink="/single-courses" class="d-inline-block">Information About UI/UX Design Degree</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        $178
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses6.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Photography</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user6.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>David Warner</span>
                                </div>
                                <h3><a routerLink="/single-courses" class="d-inline-block">Photography Photo modify and Beautiful</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        $500
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pane" id="tab2" *ngIf="currentTab === 'tab2'">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Business</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user1.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Steven Smith</span>
                                </div>
                                <h3><a routerLink="/single-courses" class="d-inline-block">Ednuv Professional IT Expert Certificate Course</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        Free
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses3.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Development</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user3.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>James Anderson</span>
                                </div>
                                <h3><a routerLink="/single-courses" class="d-inline-block">Photography Crash Course for Photographer</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bx-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 5 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 5 lessons
                                    </li>
                                    <li class="courses-price">
                                        $150
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pane" id="tab3" *ngIf="currentTab === 'tab3'">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Business</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user1.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Steven Smith</span>
                                </div>
                                <h3><a routerLink="/single-courses" class="d-inline-block">Ednuv Professional IT Expert Certificate Course</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        Free
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses2.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Design</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Sarah Taylor</span>
                                </div>
                                <h3><a routerLink="/single-courses" class="d-inline-block">Certified Graphic Design with Free Project Course</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star-half'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.5 (2 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 15 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 10 lessons
                                    </li>
                                    <li class="courses-price">
                                        $250
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses4.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Language</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user4.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Liam King</span>
                                </div>
                                <h3><a routerLink="/single-courses" class="d-inline-block">Web Design for Developing Technology with Joy</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        <span>$200</span>
                                        $195
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses5.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Management</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user5.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Lina D'Souza</span>
                                </div>
                                <h3><a routerLink="/single-courses" class="d-inline-block">Information About UI/UX Design Degree</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        $178
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses6.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Photography</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user6.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>David Warner</span>
                                </div>
                                <h3><a routerLink="/single-courses" class="d-inline-block">Photography Photo modify and Beautiful</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        $500
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pane" id="tab4" *ngIf="currentTab === 'tab4'">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses2.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Design</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Sarah Taylor</span>
                                </div>
                                <h3><a routerLink="/single-courses" class="d-inline-block">Certified Graphic Design with Free Project Course</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star-half'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.5 (2 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 15 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 10 lessons
                                    </li>
                                    <li class="courses-price">
                                        $250
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses3.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Development</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user3.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>James Anderson</span>
                                </div>
                                <h3><a routerLink="/single-courses" class="d-inline-block">Photography Crash Course for Photographer</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bx-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 5 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 5 lessons
                                    </li>
                                    <li class="courses-price">
                                        $150
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses4.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Language</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user4.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Liam King</span>
                                </div>
                                <h3><a routerLink="/single-courses" class="d-inline-block">Web Design for Developing Technology with Joy</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        <span>$200</span>
                                        $195
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses6.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Photography</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user6.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>David Warner</span>
                                </div>
                                <h3><a routerLink="/single-courses" class="d-inline-block">Photography Photo modify and Beautiful</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        $500
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pane" id="tab5" *ngIf="currentTab === 'tab5'">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Business</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user1.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Steven Smith</span>
                                </div>
                                <h3><a routerLink="/single-courses" class="d-inline-block">Ednuv Professional IT Expert Certificate Course</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        Free
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses4.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Language</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user4.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Liam King</span>
                                </div>
                                <h3><a routerLink="/single-courses" class="d-inline-block">Web Design for Developing Technology with Joy</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        <span>$200</span>
                                        $195
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pane" id="tab6" *ngIf="currentTab === 'tab6'">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses2.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Design</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Sarah Taylor</span>
                                </div>
                                <h3><a routerLink="/single-courses" class="d-inline-block">Certified Graphic Design with Free Project Course</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star-half'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.5 (2 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 15 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 10 lessons
                                    </li>
                                    <li class="courses-price">
                                        $250
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses3.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Development</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user3.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>James Anderson</span>
                                </div>
                                <h3><a routerLink="/single-courses" class="d-inline-block">Photography Crash Course for Photographer</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bx-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 5 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 5 lessons
                                    </li>
                                    <li class="courses-price">
                                        $150
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses5.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Management</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user5.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Lina D'Souza</span>
                                </div>
                                <h3><a routerLink="/single-courses" class="d-inline-block">Information About UI/UX Design Degree</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        $178
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pane" id="tab7" *ngIf="currentTab === 'tab7'">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses2.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Design</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Sarah Taylor</span>
                                </div>
                                <h3><a routerLink="/single-courses" class="d-inline-block">Certified Graphic Design with Free Project Course</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star-half'></i>
                                    </div>
                                    <div class="rating-total">
                                        4.5 (2 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 15 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 10 lessons
                                    </li>
                                    <li class="courses-price">
                                        $250
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses4.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Language</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user4.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Liam King</span>
                                </div>
                                <h3><a routerLink="/single-courses" class="d-inline-block">Web Design for Developing Technology with Joy</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        <span>$200</span>
                                        $195
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses5.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Management</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user5.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>Lina D'Souza</span>
                                </div>
                                <h3><a routerLink="/single-courses" class="d-inline-block">Information About UI/UX Design Degree</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        $178
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses6.jpg" alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">Photography</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/user6.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>David Warner</span>
                                </div>
                                <h3><a routerLink="/single-courses" class="d-inline-block">Photography Photo modify and Beautiful</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price">
                                        $500
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>