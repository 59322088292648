<section
    class="experience-area ptb-100 bg-f5faf8"
    [class.dark-experience-area]="themeService.isDark()"
>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="experience-content">
                    <span class="sub-title">Join our World Famous Training Course... Online!</span>
                    <h2 class="playfair-display">This Isn’t a Diet, It’s a Lifestyle</h2>
                    <p>Raque training programs can bring you a super exciting experience of learning through online! You never face any negative experience while enjoying your classes virtually by sitting in your comfort zone. Our flexible learning initiatives will help you to learn better and quicker than the traditional ways of learning skills.</p>
                    <ul class="features-list">
                        <li><span><i class="bx bx-check"></i> Vegetable Intake</span></li>
                        <li><span><i class="bx bx-check"></i> An Apple a Day</span></li>
                        <li><span><i class="bx bx-check"></i> Good Nutrition</span></li>
                        <li><span><i class="bx bx-check"></i> Our Principles</span></li>
                        <li><span><i class="bx bx-check"></i> Healthy Life</span></li>
                        <li><span><i class="bx bx-check"></i> Personalized Plan</span></li>
                    </ul>
                    <a routerLink="/register" class="default-btn"><i class='bx bx-user-circle icon-arrow before'></i><span class="label">Join For Free</span><i class="bx bx-user-circle icon-arrow after"></i></a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="experience-image">
                    <img src="assets/img/health-coaching/experience-img1.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
    <div class="health-coaching-shape3"><img src="assets/img/health-coaching/health-coaching-shape3.png" alt="image"></div>
    <div class="health-coaching-shape4"><img src="assets/img/health-coaching/health-coaching-shape4.png" alt="image"></div>
</section>