<div class="gym-home-slides">
    <owl-carousel-o [options]="gymHomeSlides">
        <ng-template carouselSlide>
            <div class="gym-main-banner bg-dark bg-1">
                <div class="container">
                    <div class="gym-banner-content">
                        <span class="sub-title">Get start at Everywhere</span>
                        <h1>Use Your Health & Fitness Expertise to Earn A Living Online</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                        <a routerLink="/register" class="default-btn"><i class='bx bx-user-circle icon-arrow before'></i><span class="label">Join For Free</span><i class="bx bx-user-circle icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="gym-main-banner bg-dark bg-2">
                <div class="container">
                    <div class="gym-banner-content">
                        <span class="sub-title">Get start at Everywhere</span>
                        <h1>Use Your Health & Fitness Expertise to Earn A Living Online</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                        <a routerLink="/register" class="default-btn"><i class='bx bx-user-circle icon-arrow before'></i><span class="label">Join For Free</span><i class="bx bx-user-circle icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>