<div class="login-area" [class.dark-login-area]="themeService.isDark()">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-image">
                <img src="assets/img/login-bg4.jpg" alt="image" />
            </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="login-form">
                            <div
                                class="logo"
                                [class.d-none]="themeService.isDark()"
                            >
                                <a routerLink="/">
                                    <img
                                        src="assets/img/avatar/ppraLogo_no_bg.png"
                                        alt="image"
                                    />
                                </a>
                            </div>
                            <div
                                class="logo d-none"
                                [class.d-block]="themeService.isDark()"
                            >
                                <a routerLink="/">
                                    <img
                                        src="assets/img/avatar/ppraLogo_no_bg.png"
                                        alt="image"
                                    />
                                </a>
                            </div>
                            <h3>Welcome to PPRA CPD Portal</h3>
                            <p>
                                New to PPRA CPD?
                                <a routerLink="/register">Sign up</a>
                            </p>
                            <form>
                                <div class="form-group">
                                    <input
                                        type="email"
                                        placeholder="Your email address"
                                        class="form-control"
                                    />
                                </div>
                                <div class="form-group">
                                    <input
                                        type="password"
                                        placeholder="Your password"
                                        class="form-control"
                                    />
                                </div>
                                <button
                                    type="submit"
                                    routerLink="/my-dashboard"
                                >
                                    Login
                                </button>
                                <div class="forgot-password">
                                    <a routerLink="/">Forgot Password?</a>
                                </div>
                                <!-- <div class="connect-with-social">
                                    <button type="submit" class="facebook">
                                        <i class="bx bxl-facebook"></i> Connect
                                        with Facebook
                                    </button>
                                    <button type="submit" class="twitter">
                                        <i class="bx bxl-twitter"></i> Connect
                                        with Twitter
                                    </button>
                                </div> -->
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
