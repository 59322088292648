<app-header-style-five></app-header-style-five>

<app-homeeight-main-banner></app-homeeight-main-banner>

<app-services></app-services>

<app-homeeight-about></app-homeeight-about>

<app-homeeight-courses></app-homeeight-courses>

<app-funfacts-feedback></app-funfacts-feedback>

<section class="faq-area bg-f8e8e9 pb-100">
    <app-faq></app-faq>
    <div class="divider bg-f9f9f9"></div>
</section>

<app-homeeight-events></app-homeeight-events>

<app-homeeight-blog></app-homeeight-blog>

<div class="partner-area bg-color ptb-100">
    <div class="container">
        <app-partner-style-one></app-partner-style-one>
    </div>
</div>

<app-premium-access></app-premium-access>