<app-header-style-seven></app-header-style-seven>

<app-hometwelve-main-banner></app-hometwelve-main-banner>

<app-hometwelve-popular-courses></app-hometwelve-popular-courses>

<app-trending-categories></app-trending-categories>

<app-hometwelve-about></app-hometwelve-about>

<div class="motivation-partner-area bg-color-with-gradient ptb-100">
    <div class="container">
        <app-partner-style-one></app-partner-style-one>
    </div>
</div>

<app-hometwelve-courses></app-hometwelve-courses>

<app-our-growth></app-our-growth>

<app-instructors-style-four></app-instructors-style-four>

<app-discover-ednuv></app-discover-ednuv>

<app-hometwelve-blog></app-hometwelve-blog>