<div class="training-courses-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="training-courses-image">
                    <img src="assets/img/motivation-course/training-courses.png" alt="image">
                    <div class="wrap-shape">
                        <img src="assets/img/motivation-course/training-courses-shape.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="training-courses-content">
                    <span class="sub-title">COURSE</span>
                    <h3>All kinds of online employee training courses at low cost</h3>
                    <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae donec velit neque auctor sit amet aliquam vel ullamcorper sit amet ligula quisque velit nisi pretium ut lacinia in elementum id enim.</p>
                    <div class="training-courses-btn">
                        <a routerLink="/courses-2-columns-style-1" class="default-btn">
                            <i class='bx bx-move-horizontal icon-arrow before'></i>
                            <span class="label">Start Courses</span>
                            <i class="bx bx-move-horizontal icon-arrow after"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>