<div
    class="boxes-area"
    [class.dark-boxes-area]="themeService.isDark()"
>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-boxes-item">
                    <h3>Learn The Latest Skills</h3>
                    <p>Like business analytics, graphic design, Python, and more.</p>
                    <a routerLink="/courses-2-columns-style-1" class="boxes-btn">View Courses<i class='bx bx-plus'></i></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-boxes-item bg-image">
                    <h3>100k Online Courses</h3>
                    <p>In high-demand fields like IT, AI and cloud engineering.</p>
                    <a routerLink="/courses-2-columns-style-2" class="boxes-btn">View More<i class='bx bx-plus'></i></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-boxes-item bg-color">
                    <h3>Earn a Certificate</h3>
                    <p>From a leading university in business, computer science, and more.</p>
                    <a routerLink="/courses-3-columns-style-1" class="boxes-btn">View Courses<i class='bx bx-plus'></i></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-boxes-item">
                    <h3>Up Your Skill</h3>
                    <p>With on-demand training and development programs.</p>
                    <a routerLink="/courses-4-columns-style-1" class="boxes-btn">View More<i class='bx bx-plus'></i></a>
                </div>
            </div>
        </div>
    </div>
</div>