<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg3">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Courses</li>
            </ul>
            <h2>Courses 2 Columns</h2>
        </div>
    </div>
</div>

<div
    class="courses-area ptb-100"
    [class.dark-courses-area]="themeService.isDark()"
>
    <div class="container">
        <div class="courses-topbar">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-4">
                    <div class="topbar-result-count">
                        <p>Showing 1 – 6 of 54</p>
                    </div>
                </div>
                <div class="col-lg-8 col-md-8">
                    <div class="topbar-ordering-and-search">
                        <div class="row align-items-center">
                            <div
                                class="col-lg-3 col-md-5 offset-lg-4 offset-md-1"
                            >
                                <div class="topbar-ordering">
                                    <select class="form-select">
                                        <option>Sort by popularity</option>
                                        <option>Sort by latest</option>
                                        <option>Default sorting</option>
                                        <option>Sort by rating</option>
                                        <option>Sort by new</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6">
                                <div class="topbar-search">
                                    <form>
                                        <label
                                            ><i class="bx bx-search"></i
                                        ></label>
                                        <input
                                            type="text"
                                            class="input-search"
                                            placeholder="Search here..."
                                        />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="single-courses-item mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"
                            ><img
                                src="assets/img/courses/courses1.jpg"
                                class="w-100"
                                alt="image"
                        /></a>
                    </div>
                    <div class="courses-content">
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            <div
                                class="course-author d-flex align-items-center"
                            >
                                <img
                                    src="assets/img/user1.jpg"
                                    class="shadow"
                                    alt="image"
                                />
                                <span>Steven Smith</span>
                            </div>
                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star-half"></i>
                                </div>
                                <div class="rating-total">4.5 (2)</div>
                            </div>
                        </div>
                        <h3>
                            <a
                                routerLink="/single-courses"
                                class="d-inline-block"
                                >Raque Professional IT Expert Certificate
                                Course</a
                            >
                        </h3>
                        <p>
                            Education encompasses both the teaching and learning
                            of knowledge.
                        </p>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class="bx bx-user"></i> 10 students
                            </li>
                            <li class="courses-lesson">
                                <i class="bx bx-book-open"></i> 6 lessons
                            </li>
                            <li class="courses-price">Free</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="single-courses-item mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"
                            ><img
                                src="assets/img/courses/courses2.jpg"
                                class="w-100"
                                alt="image"
                        /></a>
                    </div>
                    <div class="courses-content">
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            <div
                                class="course-author d-flex align-items-center"
                            >
                                <img
                                    src="assets/img/user2.jpg"
                                    class="shadow"
                                    alt="image"
                                />
                                <span>Sarah Taylor</span>
                            </div>

                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star-half"></i>
                                </div>
                                <div class="rating-total">4.5 (2)</div>
                            </div>
                        </div>
                        <h3>
                            <a
                                routerLink="/single-courses"
                                class="d-inline-block"
                                >Certified Graphic Design with Free Project
                                Course</a
                            >
                        </h3>
                        <p>
                            Education encompasses both the teaching and learning
                            of knowledge.
                        </p>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class="bx bx-user"></i> 15 students
                            </li>
                            <li class="courses-lesson">
                                <i class="bx bx-book-open"></i> 10 lessons
                            </li>
                            <li class="courses-price">$250</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="single-courses-item mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"
                            ><img
                                src="assets/img/courses/courses3.jpg"
                                class="w-100"
                                alt="image"
                        /></a>
                    </div>
                    <div class="courses-content">
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            <div
                                class="course-author d-flex align-items-center"
                            >
                                <img
                                    src="assets/img/user3.jpg"
                                    class="shadow"
                                    alt="image"
                                />
                                <span>Anderson</span>
                            </div>
                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bx-star"></i>
                                </div>
                                <div class="rating-total">4.0 (1)</div>
                            </div>
                        </div>
                        <h3>
                            <a
                                routerLink="/single-courses"
                                class="d-inline-block"
                                >Photography Crash Course for Photographer</a
                            >
                        </h3>
                        <p>
                            Education encompasses both the teaching and learning
                            of knowledge.
                        </p>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class="bx bx-user"></i> 5 students
                            </li>
                            <li class="courses-lesson">
                                <i class="bx bx-book-open"></i> 5 lessons
                            </li>
                            <li class="courses-price">$150</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="single-courses-item mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"
                            ><img
                                src="assets/img/courses/courses4.jpg"
                                class="w-100"
                                alt="image"
                        /></a>
                    </div>
                    <div class="courses-content">
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            <div
                                class="course-author d-flex align-items-center"
                            >
                                <img
                                    src="assets/img/user4.jpg"
                                    class="shadow"
                                    alt="image"
                                />
                                <span>Liam King</span>
                            </div>
                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bx-star"></i>
                                </div>
                                <div class="rating-total">4.0 (1)</div>
                            </div>
                        </div>
                        <h3>
                            <a
                                routerLink="/single-courses"
                                class="d-inline-block"
                                >Web Design for Developing Technology with
                                Joy</a
                            >
                        </h3>
                        <p>
                            Education encompasses both the teaching and learning
                            of knowledge.
                        </p>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class="bx bx-user"></i> 10 students
                            </li>
                            <li class="courses-lesson">
                                <i class="bx bx-book-open"></i> 6 lessons
                            </li>
                            <li class="courses-price">
                                <span>$200</span>
                                $195
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="single-courses-item mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"
                            ><img
                                src="assets/img/courses/courses5.jpg"
                                class="w-100"
                                alt="image"
                        /></a>
                    </div>
                    <div class="courses-content">
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            <div
                                class="course-author d-flex align-items-center"
                            >
                                <img
                                    src="assets/img/user5.jpg"
                                    class="shadow"
                                    alt="image"
                                />
                                <span>Lina</span>
                            </div>
                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                </div>
                                <div class="rating-total">5.0 (1)</div>
                            </div>
                        </div>
                        <h3>
                            <a
                                routerLink="/single-courses"
                                class="d-inline-block"
                                >Information About UI/UX Design Degree</a
                            >
                        </h3>
                        <p>
                            Education encompasses both the teaching and learning
                            of knowledge.
                        </p>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class="bx bx-user"></i> 5 students
                            </li>
                            <li class="courses-lesson">
                                <i class="bx bx-book-open"></i> 5 lessons
                            </li>
                            <li class="courses-price">$178</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="single-courses-item mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"
                            ><img
                                src="assets/img/courses/courses6.jpg"
                                class="w-100"
                                alt="image"
                        /></a>
                    </div>
                    <div class="courses-content">
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            <div
                                class="course-author d-flex align-items-center"
                            >
                                <img
                                    src="assets/img/user6.jpg"
                                    class="shadow"
                                    alt="image"
                                />
                                <span>David Warner</span>
                            </div>

                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bx-star"></i>
                                </div>
                                <div class="rating-total">4.0 (1)</div>
                            </div>
                        </div>
                        <h3>
                            <a
                                routerLink="/single-courses"
                                class="d-inline-block"
                                >Photography Photo modify and Beautiful</a
                            >
                        </h3>
                        <p>
                            Education encompasses both the teaching and learning
                            of knowledge.
                        </p>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class="bx bx-user"></i> 11 students
                            </li>
                            <li class="courses-lesson">
                                <i class="bx bx-book-open"></i> 1 lessons
                            </li>
                            <li class="courses-price">$500</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="pagination-area text-center">
                    <span class="page-numbers current" aria-current="page"
                        >1</span
                    >
                    <a
                        routerLink="/courses-2-columns-style-2"
                        class="page-numbers"
                        >2</a
                    >
                    <a
                        routerLink="/courses-2-columns-style-2"
                        class="page-numbers"
                        >3</a
                    >
                    <a
                        routerLink="/courses-2-columns-style-2"
                        class="page-numbers"
                        >4</a
                    >
                    <a
                        routerLink="/courses-2-columns-style-2"
                        class="page-numbers"
                        >5</a
                    >
                    <a
                        routerLink="/courses-2-columns-style-2"
                        class="next page-numbers"
                        ><i class="bx bx-chevron-right"></i
                    ></a>
                </div>
            </div>
        </div>
    </div>
</div>
