import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-homeeleven-blog',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './homeeleven-blog.component.html',
  styleUrls: ['./homeeleven-blog.component.scss']
})
export class HomeelevenBlogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
