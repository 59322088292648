<app-header-style-five></app-header-style-five>

<app-hometen-main-banner></app-hometen-main-banner>

<app-language-category></app-language-category>

<app-why-choose-us></app-why-choose-us>

<app-homeeight-courses></app-homeeight-courses>

<app-funfacts-style-two></app-funfacts-style-two>

<app-students-feedback></app-students-feedback>

<app-how-to-apply></app-how-to-apply>

<app-homeeight-events></app-homeeight-events>

<app-free-trial></app-free-trial>

<app-homeeight-blog></app-homeeight-blog>

<div class="partner-area bg-color ptb-100">
    <div class="container">
        <app-partner-style-one></app-partner-style-one>
    </div>
</div>

<app-language-subscribe></app-language-subscribe>