<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg3">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/my-dashboard">Dashboard</a></li>
                <li>PDP</li>
            </ul>
            <h2>Personal Development Plan</h2>
        </div>
    </div>
</div>

<div
    class="faq-area pt-100 pb-70"
    [class.dark-faq-area]="themeService.isDark()"
>
    <div class="container">
        <div class="faq-accordion-tab">
            <ul class="nav-tabset">
                <li
                    class="nav-tab"
                    [ngClass]="{ active: currentTab === 'tab1' }"
                >
                    <span class="active" (click)="switchTab($event, 'tab1')"
                        >Development Plan</span
                    >
                    <div class="right-arrow"></div>
                </li>
                <li
                    class="nav-tab"
                    [ngClass]="{ active: currentTab === 'tab2' }"
                >
                    <span (click)="switchTab($event, 'tab2')"
                        ><div class="left-arrow"></div>
                        Modules</span
                    >
                    <div class="right-arrow"></div>
                </li>
                <li
                    class="nav-tab"
                    [ngClass]="{ active: currentTab === 'tab3' }"
                >
                    <span (click)="switchTab($event, 'tab3')">Summary</span>
                    <!-- <div class="right-arrow"></div> -->
                </li>
                <!-- <li
                    class="nav-tab"
                    [ngClass]="{ active: currentTab === 'tab4' }"
                >
                    <span (click)="switchTab($event, 'tab4')"
                        >Usage Guides</span
                    >
                </li> -->
            </ul>
            <div class="tabs-container">
                <div class="pane" id="tab1" *ngIf="currentTab === 'tab1'">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="faq-item">
                                <h3>
                                    Please complete and submit PDP for 2024
                                    <a routerLink="/" class="myDashboard-logout"
                                        ><i class="bx bxs-add-to-queue"></i> New
                                        Plan</a
                                    >
                                </h3>

                                <div
                                    class="recent-orders-table table-responsive"
                                >
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Title</th>
                                                <th>Plan Year</th>
                                                <th>Date Created</th>
                                                <th>Submission Date</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    0148016- Development Plan -
                                                    0
                                                </td>
                                                <td>2023</td>
                                                <td>26 Mar 2024</td>
                                                <td>Yes, 26 Mar 2024</td>
                                                <td>Locked</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    0148016- Development Plan -
                                                    0
                                                </td>
                                                <td>2023</td>
                                                <td>26 Mar 2024</td>
                                                <td>Yes, 26 Mar 2024</td>
                                                <td>Locked</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    0148016- Development Plan -
                                                    0
                                                </td>
                                                <td>2023</td>
                                                <td>26 Mar 2024</td>
                                                <td>Yes, 26 Mar 2024</td>
                                                <td>Locked</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    0148016- Development Plan -
                                                    0
                                                </td>
                                                <td>2023</td>
                                                <td>26 Mar 2024</td>
                                                <td>Yes, 26 Mar 2024</td>
                                                <td>Locked</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    0148016- Development Plan -
                                                    0
                                                </td>
                                                <td>2023</td>
                                                <td>26 Mar 2024</td>
                                                <td>Yes, 26 Mar 2024</td>
                                                <td>Locked</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab2" *ngIf="currentTab === 'tab2'">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="faq-item">
                                <h3>
                                    PDP Modules for 2024
                                    <!-- <a routerLink="/" class="myDashboard-logout"
                                        ><i class="bx bxs-add-to-queue"></i> New
                                        Plan</a
                                    > -->
                                </h3>

                                <div
                                    class="recent-orders-table table-responsive"
                                >
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Title</th>
                                                <th>Plan Year</th>
                                                <th>Date Created</th>
                                                <th>Submission Date</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    0148016- Development Plan -
                                                    0
                                                </td>
                                                <td>2023</td>
                                                <td>26 Mar 2024</td>
                                                <td>Yes, 26 Mar 2024</td>
                                                <td>Locked</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    0148016- Development Plan -
                                                    0
                                                </td>
                                                <td>2023</td>
                                                <td>26 Mar 2024</td>
                                                <td>Yes, 26 Mar 2024</td>
                                                <td>Locked</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    0148016- Development Plan -
                                                    0
                                                </td>
                                                <td>2023</td>
                                                <td>26 Mar 2024</td>
                                                <td>Yes, 26 Mar 2024</td>
                                                <td>Locked</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    0148016- Development Plan -
                                                    0
                                                </td>
                                                <td>2023</td>
                                                <td>26 Mar 2024</td>
                                                <td>Yes, 26 Mar 2024</td>
                                                <td>Locked</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    0148016- Development Plan -
                                                    0
                                                </td>
                                                <td>2023</td>
                                                <td>26 Mar 2024</td>
                                                <td>Yes, 26 Mar 2024</td>
                                                <td>Locked</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab3" *ngIf="currentTab === 'tab3'">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="faq-item">
                                <h3>
                                    PDP Summary for 2024
                                    <!-- <a routerLink="/" class="myDashboard-logout"
                                        ><i class="bx bxs-add-to-queue"></i> New
                                        Plan</a
                                    > -->
                                </h3>

                                <div
                                    class="recent-orders-table table-responsive"
                                >
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Title</th>
                                                <th>Plan Year</th>
                                                <th>Date Created</th>
                                                <th>Submission Date</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    0148016- Development Plan -
                                                    0
                                                </td>
                                                <td>2023</td>
                                                <td>26 Mar 2024</td>
                                                <td>Yes, 26 Mar 2024</td>
                                                <td>Locked</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    0148016- Development Plan -
                                                    0
                                                </td>
                                                <td>2023</td>
                                                <td>26 Mar 2024</td>
                                                <td>Yes, 26 Mar 2024</td>
                                                <td>Locked</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    0148016- Development Plan -
                                                    0
                                                </td>
                                                <td>2023</td>
                                                <td>26 Mar 2024</td>
                                                <td>Yes, 26 Mar 2024</td>
                                                <td>Locked</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    0148016- Development Plan -
                                                    0
                                                </td>
                                                <td>2023</td>
                                                <td>26 Mar 2024</td>
                                                <td>Yes, 26 Mar 2024</td>
                                                <td>Locked</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    0148016- Development Plan -
                                                    0
                                                </td>
                                                <td>2023</td>
                                                <td>26 Mar 2024</td>
                                                <td>Yes, 26 Mar 2024</td>
                                                <td>Locked</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="pane" id="tab4" *ngIf="currentTab === 'tab4'">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: What’s the difference between college and
                                    university?
                                </h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: What are the different types of
                                    undergraduate degrees?
                                </h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: What are the different types of graduate
                                    degrees?
                                </h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: Can you work while studying in the United
                                    States?
                                </h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is distance education?</h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What do I need to know about mobile?</h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is content marketing?</h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Do I need a blog?</h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>

<!-- <app-become-instructor-partner></app-become-instructor-partner> -->
