<div
    class="new-comers-area ptb-100"
    [class.dark-new-comers-area]="themeService.isDark()"
>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="new-comers-image">
                    <img src="assets/img/motivation-course/new-comers/new-comers-1.png" alt="image">
                    <div class="wrap-shape">
                        <img src="assets/img/motivation-course/new-comers/shape.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="new-comers-content">
                    <span class="sub-title">FEATURED COURSE</span>
                    <h3>Newcomers will benefit greatly from doing our feature courses</h3>
                    <h4>We can support student forum 24/7 for national and international students.</h4>
                    <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae donec velit neque auctor sit amet aliquam vel ullamcorper sit amet ligula quisque velit nisi pretium ut lacinia in elementum id enim.</p>
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                            <ul class="new-comers-list">
                                <li>
                                    <i class='bx bx-check'></i>
                                    Money-Back Guarantee
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Downloadable Resources
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <ul class="new-comers-list">
                                <li>
                                    <i class='bx bx-check'></i>
                                    Certificate Of Completion
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    10+ More Lessons
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="new-comers-btn">
                        <a routerLink="/courses-2-columns-style-1" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">Start Courses </span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>