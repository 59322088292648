<section class="blog-area bg-dark bg-0b0517 pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our News</span>
            <h2>Check Out Our Latest Blog</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box without-bg-color">
                    <div class="post-image">
                        <a routerLink="/single-blog" class="d-block">
                            <img src="assets/img/blog/10.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-style-1" class="category">GYM</a>
                        <h3><a routerLink="/single-blog">How to Prepare for Gyms Reopening</a></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box without-bg-color">
                    <div class="post-image">
                        <a routerLink="/single-blog" class="d-block">
                            <img src="assets/img/blog/11.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-style-1" class="category">Dark</a>
                        <h3><a routerLink="/single-blog">How to support your immune system</a></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box without-bg-color">
                    <div class="post-image">
                        <a routerLink="/single-blog" class="d-block">
                            <img src="assets/img/blog/12.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-style-1" class="category">Trainer</a>
                        <h3><a routerLink="/single-blog">Dumbbell Only Upper Body Workout</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>