import { Component, OnInit } from "@angular/core";
import { ThemeCustomizerService } from "../../common/theme-customizer/theme-customizer.service";
import { HeaderStyleTwoComponent } from "../../common/header-style-two/header-style-two.component";
import { RouterLink } from "@angular/router";
// Import ng-circle-progress
import {
    CircleProgressOptions,
    NgCircleProgressModule,
} from "ng-circle-progress";
import { CommonModule } from "@angular/common";

@Component({
    selector: "app-my-dashboard-page",
    standalone: true,
    imports: [
        HeaderStyleTwoComponent,
        RouterLink,
        NgCircleProgressModule,
        CommonModule,
    ],
    templateUrl: "./my-dashboard-page.component.html",
    styleUrls: ["./my-dashboard-page.component.scss"],
    providers: [CircleProgressOptions],
})
export class MyDashboardPageComponent implements OnInit {
    isToggled = false;
    cpd: boolean = false;
    pdp: boolean = true;
    constructor(public themeService: ThemeCustomizerService) {
        this.themeService.isToggled$.subscribe((isToggled) => {
            this.isToggled = isToggled;
        });
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    ngOnInit(): void {}

    isActiveCpd() {
        this.cpd = true;
        this.pdp = false;
    }

    isActivePdp() {
        this.cpd = false;
        this.pdp = true;
    }
}
