<app-header-style-seven></app-header-style-seven>

<app-homethirteen-main-banner></app-homethirteen-main-banner>

<app-homethirteen-popular-courses></app-homethirteen-popular-courses>

<app-features-stye-three></app-features-stye-three>

<app-homethirteen-about></app-homethirteen-about>

<app-homethirteen-courses></app-homethirteen-courses>

<div class="motivation-partner-area bg-color-with-gradient ptb-100">
    <div class="container">
        <app-partner-style-one></app-partner-style-one>
    </div>
</div>

<div class="overview-connections-area pt-100 pb-70">
    <app-make-connections></app-make-connections>
</div>

<app-instructors-style-four></app-instructors-style-four>

<app-become-partner-teacher></app-become-partner-teacher>

<div class="testimonials-wrap-area ptb-100">
    <app-happy-students-feedback></app-happy-students-feedback>
</div>

<app-hometwelve-blog></app-hometwelve-blog>