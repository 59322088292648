<div class="register-area" [class.dark-register-area]="themeService.isDark()">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="register-image">
                <img src="assets/img/register-bg.jpg" alt="image" />
            </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0">
            <div class="register-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="register-form">
                            <div
                                class="logo"
                                [class.d-none]="themeService.isDark()"
                            >
                                <a routerLink="/">
                                    <img
                                        src="assets/img/avatar/ppraLogo_no_bg.png"
                                        alt="image"
                                    />
                                </a>
                            </div>
                            <div
                                class="logo d-none"
                                [class.d-block]="themeService.isDark()"
                            >
                                <a routerLink="/">
                                    <img
                                        src="assets/img/avatar/ppraLogo_no_bg.png"
                                        alt="image"
                                    />
                                </a>
                            </div>
                            <h3>Open up your PPRA CPD Account now</h3>
                            <p>
                                Already signed up?
                                <a routerLink="/login">Log in</a>
                            </p>
                            <form>
                                <div class="form-group">
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        placeholder="Your email address"
                                        class="form-control"
                                    />
                                </div>
                                <div class="form-group">
                                    <input
                                        type="password"
                                        name="password"
                                        id="password"
                                        placeholder="Create a password"
                                        class="form-control"
                                    />
                                </div>
                                <button type="submit">Sign Up</button>
                                <!-- <div class="connect-with-social">
                                    <span>Or</span>
                                    <button type="submit" class="facebook">
                                        <i class="bx bxl-facebook"></i> Connect
                                        with Facebook
                                    </button>
                                    <button type="submit" class="twitter">
                                        <i class="bx bxl-twitter"></i> Connect
                                        with Twitter
                                    </button>
                                </div> -->
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
