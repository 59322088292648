<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>e-learning</li>
            </ul>
            <h2>CPD e-learning</h2>
        </div>
    </div>
</div>

<div
    class="courses-area ptb-73"
    [class.dark-courses-area]="themeService.isDark()"
>
    <div class="container">
        <div class="courses-topbar">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-4">
                    <div class="topbar-result-count">
                        <p>Showing 1 – 1 of 1</p>
                    </div>
                </div>
                <div class="col-lg-8 col-md-8">
                    <div class="topbar-ordering-and-search">
                        <div class="row align-items-center">
                            <div
                                class="col-lg-3 col-md-5 offset-lg-4 offset-md-1"
                            >
                                <div class="topbar-ordering">
                                    <select class="form-select">
                                        <option>Sort by popularity</option>
                                        <option>Sort by latest</option>
                                        <option>Default sorting</option>
                                        <option>Sort by rating</option>
                                        <option>Sort by new</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6">
                                <div class="topbar-search">
                                    <form>
                                        <label
                                            ><i class="bx bx-search"></i
                                        ></label>
                                        <input
                                            type="text"
                                            class="input-search"
                                            placeholder="Search here..."
                                        />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="single-courses-list-box mb-30">
                            <div class="box-item">
                                <div class="courses-image">
                                    <div class="image bg-1">
                                        <img
                                            src="http://img.youtube.com/vi/OncQFiz83VI/maxresdefault.jpg"
                                            alt="image"
                                            style="height: 100%; display: block"
                                        />
                                        <!-- <lightgallery
                                            [settings]="settings"
                                            [onBeforeSlide]="onBeforeSlide"
                                        >
                                            <a
                                                data-lg-size="1280-720"
                                                data-src="https://youtu.be/OncQFiz83VI"
                                                data-sub-html="<p>PPRA Video</p>"
                                            >
                                                <img
                                                    src="http://img.youtube.com/vi/OncQFiz83VI/maxresdefault.jpg"
                                                    alt="image"
                                                    style="
                                                        height: 100%;
                                                        display: block;
                                                    "
                                                />
                                                <a
                                                    href="https://youtu.be/OncQFiz83VI"
                                                    class="video-btn popup-youtube"
                                                >
                                                    <i class="bx bx-play"></i>
                                                </a>
                                            </a>
                                        </lightgallery> -->
                                    </div>
                                </div>
                                <div class="courses-desc">
                                    <div class="courses-content">
                                        <div
                                            class="course-author d-flex align-items-center"
                                        >
                                            <img
                                                src="assets/img/user1.jpg"
                                                class="rounded-circle mr-2"
                                                alt="image"
                                            />
                                            <span>Steven Smith</span>
                                        </div>
                                        <h3>
                                            <a
                                                routerLink="/course-details"
                                                class="d-inline-block"
                                                >Effective Communication
                                                Negotiation Skills</a
                                            >
                                        </h3>
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                            </div>
                                            <div class="rating-total">
                                                5.0 (1 rating)
                                            </div>
                                        </div>
                                        <p>
                                            The module seeks to assist property
                                            practitioners to learn, understand
                                            and use effective communication and
                                            negotiation skills...
                                            <!-- <span
                                                style="color: red"
                                                routerLink="/course-details"
                                                ><strong>
                                                    Read More</strong
                                                ></span
                                            > -->
                                        </p>
                                    </div>
                                    <div class="courses-box-footer">
                                        <ul>
                                            <li class="students-number">
                                                <i class="bx bx-user"></i> 10
                                                students
                                            </li>
                                            <li class="courses-lesson">
                                                <i class="bx bx-book-open"></i>
                                                <abbr
                                                    title="Study Materials"
                                                    style="
                                                        text-decoration: none;
                                                        cursor: pointer;
                                                    "
                                                >
                                                    Study Notes</abbr
                                                >
                                            </li>
                                            <li
                                                style="cursor: pointer"
                                                routerLink="/course-details"
                                                class="courses-price"
                                            >
                                                Read More
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-lg-12 col-md-12">
                        <div class="single-courses-list-box mb-30">
                            <div class="box-item">
                                <div class="courses-image">
                                    <div class="image bg-2">
                                        <img
                                            src="assets/img/courses/courses2.jpg"
                                            alt="image"
                                        />
                                        <a
                                            routerLink="/single-courses"
                                            class="link-btn"
                                        ></a>
                                        <div class="courses-tag">
                                            <a
                                                routerLink="/courses-list"
                                                class="d-block"
                                                >Design</a
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div class="courses-desc">
                                    <div class="courses-content">
                                        <div
                                            class="course-author d-flex align-items-center"
                                        >
                                            <img
                                                src="assets/img/user2.jpg"
                                                class="rounded-circle mr-2"
                                                alt="image"
                                            />
                                            <span>Sarah Taylor</span>
                                        </div>
                                        <h3>
                                            <a
                                                routerLink="/single-courses"
                                                class="d-inline-block"
                                                >Certified Graphic Design with
                                                Free Project Course</a
                                            >
                                        </h3>
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star-half"></i>
                                            </div>
                                            <div class="rating-total">
                                                4.5 (2 rating)
                                            </div>
                                        </div>
                                        <p>
                                            Education encompasses both the
                                            teaching and learning of knowledge.
                                        </p>
                                    </div>
                                    <div class="courses-box-footer">
                                        <ul>
                                            <li class="students-number">
                                                <i class="bx bx-user"></i> 15
                                                students
                                            </li>
                                            <li class="courses-lesson">
                                                <i class="bx bx-book-open"></i>
                                                10 lessons
                                            </li>
                                            <li class="courses-price">$250</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="single-courses-list-box mb-30">
                            <div class="box-item">
                                <div class="courses-image">
                                    <div class="image bg-3">
                                        <img
                                            src="assets/img/courses/courses3.jpg"
                                            alt="image"
                                        />
                                        <a
                                            routerLink="/single-courses"
                                            class="link-btn"
                                        ></a>
                                        <div class="courses-tag">
                                            <a
                                                routerLink="/courses-list"
                                                class="d-block"
                                                >Development</a
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div class="courses-desc">
                                    <div class="courses-content">
                                        <div
                                            class="course-author d-flex align-items-center"
                                        >
                                            <img
                                                src="assets/img/user3.jpg"
                                                class="rounded-circle mr-2"
                                                alt="image"
                                            />
                                            <span>James Anderson</span>
                                        </div>
                                        <h3>
                                            <a
                                                routerLink="/single-courses"
                                                class="d-inline-block"
                                                >Photography Crash Course for
                                                Photographer</a
                                            >
                                        </h3>
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bx-star"></i>
                                            </div>
                                            <div class="rating-total">
                                                4.0 (1 rating)
                                            </div>
                                        </div>
                                        <p>
                                            Education encompasses both the
                                            teaching and learning of knowledge.
                                        </p>
                                    </div>
                                    <div class="courses-box-footer">
                                        <ul>
                                            <li class="students-number">
                                                <i class="bx bx-user"></i> 5
                                                students
                                            </li>
                                            <li class="courses-lesson">
                                                <i class="bx bx-book-open"></i>
                                                5 lessons
                                            </li>
                                            <li class="courses-price">$150</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="single-courses-list-box mb-30">
                            <div class="box-item">
                                <div class="courses-image">
                                    <div class="image bg-4">
                                        <img
                                            src="assets/img/courses/courses4.jpg"
                                            alt="image"
                                        />
                                        <a
                                            routerLink="/single-courses"
                                            class="link-btn"
                                        ></a>
                                        <div class="courses-tag">
                                            <a
                                                routerLink="/courses-list"
                                                class="d-block"
                                                >Language</a
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div class="courses-desc">
                                    <div class="courses-content">
                                        <div
                                            class="course-author d-flex align-items-center"
                                        >
                                            <img
                                                src="assets/img/user4.jpg"
                                                class="rounded-circle mr-2"
                                                alt="image"
                                            />
                                            <span>Liam King</span>
                                        </div>
                                        <h3>
                                            <a
                                                routerLink="/single-courses"
                                                class="d-inline-block"
                                                >Web Design for Developing
                                                Technology with Joy</a
                                            >
                                        </h3>
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                            </div>
                                            <div class="rating-total">
                                                5.0 (1 rating)
                                            </div>
                                        </div>
                                        <p>
                                            Education encompasses both the
                                            teaching and learning of knowledge.
                                        </p>
                                    </div>
                                    <div class="courses-box-footer">
                                        <ul>
                                            <li class="students-number">
                                                <i class="bx bx-user"></i> 10
                                                students
                                            </li>
                                            <li class="courses-lesson">
                                                <i class="bx bx-book-open"></i>
                                                6 lessons
                                            </li>
                                            <li class="courses-price">
                                                <span>$200</span>
                                                $195
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="single-courses-list-box mb-30">
                            <div class="box-item">
                                <div class="courses-image">
                                    <div class="image bg-5">
                                        <img
                                            src="assets/img/courses/courses5.jpg"
                                            alt="image"
                                        />
                                        <a
                                            routerLink="/single-courses"
                                            class="link-btn"
                                        ></a>
                                        <div class="courses-tag">
                                            <a
                                                routerLink="/courses-list"
                                                class="d-block"
                                                >Management</a
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div class="courses-desc">
                                    <div class="courses-content">
                                        <div
                                            class="course-author d-flex align-items-center"
                                        >
                                            <img
                                                src="assets/img/user5.jpg"
                                                class="rounded-circle mr-2"
                                                alt="image"
                                            />
                                            <span>Lina D'Souza</span>
                                        </div>
                                        <h3>
                                            <a
                                                routerLink="/single-courses"
                                                class="d-inline-block"
                                                >Information About UI/UX Design
                                                Degree</a
                                            >
                                        </h3>
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                            </div>
                                            <div class="rating-total">
                                                5.0 (1 rating)
                                            </div>
                                        </div>
                                        <p>
                                            Education encompasses both the
                                            teaching and learning of knowledge.
                                        </p>
                                    </div>
                                    <div class="courses-box-footer">
                                        <ul>
                                            <li class="students-number">
                                                <i class="bx bx-user"></i> 10
                                                students
                                            </li>
                                            <li class="courses-lesson">
                                                <i class="bx bx-book-open"></i>
                                                6 lessons
                                            </li>
                                            <li class="courses-price">$178</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="single-courses-list-box mb-30">
                            <div class="box-item">
                                <div class="courses-image">
                                    <div class="image bg-6">
                                        <img
                                            src="assets/img/courses/courses6.jpg"
                                            alt="image"
                                        />
                                        <a
                                            routerLink="/single-courses"
                                            class="link-btn"
                                        ></a>
                                        <div class="courses-tag">
                                            <a
                                                routerLink="/courses-list"
                                                class="d-block"
                                                >Photography</a
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div class="courses-desc">
                                    <div class="courses-content">
                                        <div
                                            class="course-author d-flex align-items-center"
                                        >
                                            <img
                                                src="assets/img/user6.jpg"
                                                class="rounded-circle mr-2"
                                                alt="image"
                                            />
                                            <span>David Warner</span>
                                        </div>
                                        <h3>
                                            <a
                                                routerLink="/single-courses"
                                                class="d-inline-block"
                                                >Photography Photo modify and
                                                Beautiful</a
                                            >
                                        </h3>
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                                <i class="bx bxs-star"></i>
                                            </div>
                                            <div class="rating-total">
                                                5.0 (1 rating)
                                            </div>
                                        </div>
                                        <p>
                                            Education encompasses both the
                                            teaching and learning of knowledge.
                                        </p>
                                    </div>
                                    <div class="courses-box-footer">
                                        <ul>
                                            <li class="students-number">
                                                <i class="bx bx-user"></i> 10
                                                students
                                            </li>
                                            <li class="courses-lesson">
                                                <i class="bx bx-book-open"></i>
                                                6 lessons
                                            </li>
                                            <li class="courses-price">$500</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="pagination-area text-center">
                            <span
                                class="page-numbers current"
                                aria-current="page"
                                >1</span
                            >
                            <!-- <a routerLink="/courses-list" class="page-numbers"
                                >2</a
                            >
                            <a routerLink="/courses-list" class="page-numbers"
                                >3</a
                            >
                            <a routerLink="/courses-list" class="page-numbers"
                                >4</a
                            >
                            <a routerLink="/courses-list" class="page-numbers"
                                >5</a
                            >
                            <a
                                routerLink="/courses-list"
                                class="next page-numbers"
                                ><i class="bx bx-chevron-right"></i
                            ></a> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <div class="widget widget_ednuv_posts_thumb">
                        <h3 class="widget-title">Popular Courses</h3>
                        <article class="item">
                            <a routerLink="/course-details" class="thumb">
                                <span class="fullimage cover" role="img">
                                    <img
                                        src="http://img.youtube.com/vi/OncQFiz83VI/default.jpg"
                                        alt=""
                                        style="height: 100%"
                                    />
                                </span>
                            </a>
                            <div class="info">
                                <time datetime="2024-06-30">Free</time>
                                <h4 class="title usmall">
                                    <a routerLink="/course-details"
                                        >Effective Communication Negotiation
                                        Skills</a
                                    >
                                </h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <!-- <article class="item">
                            <a routerLink="/single-courses" class="thumb">
                                <span
                                    class="fullimage cover bg2"
                                    role="img"
                                ></span>
                            </a>
                            <div class="info">
                                <time datetime="2024-06-30">$300</time>
                                <h4 class="title usmall">
                                    <a routerLink="/single-courses"
                                        >I Used The Web For A Day On A 50 MB
                                        Budget</a
                                    >
                                </h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-courses" class="thumb">
                                <span
                                    class="fullimage cover bg3"
                                    role="img"
                                ></span>
                            </a>
                            <div class="info">
                                <time datetime="2024-06-30">$300</time>
                                <h4 class="title usmall">
                                    <a routerLink="/single-courses"
                                        >How To Create A Responsive Popup
                                        Gallery?</a
                                    >
                                </h4>
                            </div>
                            <div class="clear"></div>
                        </article> -->
                    </div>
                    <div class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>
                        <ul>
                            <li>
                                <a routerLink="/course-details"
                                    >Communication
                                    <span class="post-count">(01)</span></a
                                >
                            </li>
                            <!-- <li>
                                <a routerLink="/blog-style-2"
                                    >Lifestyle
                                    <span class="post-count">(05)</span></a
                                >
                            </li>
                            <li>
                                <a routerLink="/blog-style-2"
                                    >Script
                                    <span class="post-count">(10)</span></a
                                >
                            </li>
                            <li>
                                <a routerLink="/blog-style-2"
                                    >Device
                                    <span class="post-count">(08)</span></a
                                >
                            </li>
                            <li>
                                <a routerLink="/blog-style-2"
                                    >Tips
                                    <span class="post-count">(01)</span></a
                                >
                            </li> -->
                        </ul>
                    </div>
                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">CPD Tags</h3>
                        <div class="tagcloud">
                            <a routerLink="/"
                                >Communication
                                <span class="tag-link-count"> (1)</span></a
                            >
                            <!-- <a routerLink="/blog-style-2"
                                >Ednuv
                                <span class="tag-link-count"> (3)</span></a
                            >
                            <a routerLink="/blog-style-2"
                                >Games
                                <span class="tag-link-count"> (2)</span></a
                            >
                            <a routerLink="/blog-style-2"
                                >Fashion
                                <span class="tag-link-count"> (2)</span></a
                            >
                            <a routerLink="/blog-style-2"
                                >Travel
                                <span class="tag-link-count"> (1)</span></a
                            >
                            <a routerLink="/blog-style-2"
                                >Smart
                                <span class="tag-link-count"> (1)</span></a
                            >
                            <a routerLink="/blog-style-2"
                                >Marketing
                                <span class="tag-link-count"> (1)</span></a
                            >
                            <a routerLink="/blog-style-2"
                                >Tips
                                <span class="tag-link-count"> (2)</span></a
                            > -->
                        </div>
                    </div>
                    <div class="widget widget_instagram">
                        <h3 class="widget-title">Instagram</h3>
                        <ul>
                            <li>
                                <a href="#" target="_blank" class="d-block"
                                    ><img
                                        src="assets/img/blog/blog1.jpg"
                                        alt="image"
                                /></a>
                            </li>
                            <li>
                                <a href="#" target="_blank" class="d-block"
                                    ><img
                                        src="assets/img/blog/blog2.jpg"
                                        alt="image"
                                /></a>
                            </li>
                            <li>
                                <a href="#" target="_blank" class="d-block"
                                    ><img
                                        src="assets/img/blog/blog3.jpg"
                                        alt="image"
                                /></a>
                            </li>
                            <li>
                                <a href="#" target="_blank" class="d-block"
                                    ><img
                                        src="assets/img/blog/blog4.jpg"
                                        alt="image"
                                /></a>
                            </li>
                            <li>
                                <a href="#" target="_blank" class="d-block"
                                    ><img
                                        src="assets/img/blog/blog5.jpg"
                                        alt="image"
                                /></a>
                            </li>
                            <li>
                                <a href="#" target="_blank" class="d-block"
                                    ><img
                                        src="assets/img/blog/blog6.jpg"
                                        alt="image"
                                /></a>
                            </li>
                        </ul>
                    </div>
                    <div class="widget widget_contact">
                        <div class="text">
                            <div class="icon">
                                <i class="bx bx-phone-call"></i>
                            </div>
                            <span>Enquiry</span>
                            <a href="tel:+27872853222">+27 87 285 3222</a>
                        </div>
                    </div>
                </aside>
            </div>
        </div>
    </div>
</div>
